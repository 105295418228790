.main[data-v-0a56c71a] {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #f7f9fc;
  color: black;
}
.main[data-v-0a56c71a] .el-row {
    height: 100%;
}
.main[data-v-0a56c71a] .el-row .el-col {
      height: 100%;
}
.main .main-card[data-v-0a56c71a] {
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-box-shadow: 0 2px 12px 0 #ccc !important;
            box-shadow: 0 2px 12px 0 #ccc !important;
    background-color: #ffffff;
    overflow-y: auto;
}
.main .main-card[data-v-0a56c71a] .el-tree-node__content {
      margin-top: 10px;
}
.main .main-card[data-v-0a56c71a] .el-tree-node__expand-icon {
      display: none;
      color: white;
      position: absolute;
      right: 10px;
}
.main .main-header[data-v-0a56c71a] {
    height: 12.03704vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.main .main-header .goToHome[data-v-0a56c71a] {
      color: #68696b;
      margin-right: 20px;
      font-weight: bold;
      cursor: pointer;
      fill: #68696b;
}
.main .main-header .goToHome[data-v-0a56c71a]:hover {
      color: #409eff;
      fill: #409eff;
}
.main .main-content[data-v-0a56c71a] {
    height: 100%;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}
.main .main-content[data-v-0a56c71a] .el-menu-item {
      color: #34a1ff !important;
      font-weight: bold;
      margin-top: 5px;
      border: 1px solid #3e9dfa;
}
.main .main-content[data-v-0a56c71a] .el-menu-item.is-active {
      color: white !important;
}
.main .main-content[data-v-0a56c71a] .el-menu-item:hover {
      color: white !important;
}
.main .main-content[data-v-0a56c71a] .el-menu-item.is-active:hover {
      color: white !important;
}
.main .main-content[data-v-0a56c71a] .el-submenu__title:hover {
      color: white !important;
}
.main .main-content[data-v-0a56c71a] .el-submenu__title {
      color: #34a1ff !important;
      font-weight: bold;
      margin-top: 5px;
      border: 1px solid #3e9dfa;
}
.main .app-container .apptype-card[data-v-0a56c71a] {
    margin-top: 12px;
    background: rgba(204, 204, 204, 0.1);
}
.main .app-container .apptype-card[data-v-0a56c71a]:hover {
      background: transparent;
}
.main .app-container .apptype-card[data-v-0a56c71a] .el-card__body {
      padding: 0px;
}
.main .app-container .apptype-card .apptype-card-header[data-v-0a56c71a] {
      font-size: 16px;
      color: #606266;
      padding: 8px 20px;
      text-align: center;
      border-top: 4px solid #ffc62e;
      border-bottom: 1px solid #ebeef5;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.main .app-container .apptype-card .apptype-card-body[data-v-0a56c71a] {
      padding: 10px;
      color: #606266;
      /*max-height: 200px;*/
      /*overflow-y: scroll;*/
}
.main .app-container .apptype-card .apptype-card-body .app-item[data-v-0a56c71a] {
        padding: 0px 10px;
        cursor: pointer;
}
.main .app-container .apptype-card .apptype-card-body .app-item[data-v-0a56c71a]:hover {
          background: rgba(204, 204, 204, 0.1);
          color: #409eff;
}
.main .app-container .apptype-card .apptype-card-body .app-item .app-item-dept[data-v-0a56c71a] {
          float: right;
          color: #409eff;
}
.main .app-container .apptype-card .apptype-card-body .app-item .app-item-dept[data-v-0a56c71a]:hover {
            -webkit-transform: scale(1.5);
                    transform: scale(1.5);
}
.main .usual-column[data-v-0a56c71a] {
    height: 60px;
}
.main .usual-column .usual-item[data-v-0a56c71a] {
      font-weight: bold;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      width: 200px;
      height: 35px;
      line-height: 35px;
      color: white;
      background-color: #0ac78c;
}
.main .usual-column .usual-item[data-v-0a56c71a]:hover {
      background-color: #0befa8ff;
}
